<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const actualRegion = ref('')

function getRegion() {
  const locale = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log('Intl', Intl.DateTimeFormat().resolvedOptions())
  const region = locale.split('/')[0] || 'default';
  return region;
}

onMounted(() => {
  actualRegion.value = getRegion()
})
</script>

<template>
  <section v-if="!content.geo_visibility || content.geo_visibility == 'world'" class="section section--download">
    <div class="section-wrapper">
      <div class="download-element-list-wrapper">
        <div
          class="download-element"
          v-for="document in props.content.download_list">
          <SharedBasicDownload :document="document" />
        </div>
      </div>
    </div>
  </section>
  <section v-else-if="content.geo_visibility == 'world_but_americas' && actualRegion != 'America'" class="section section--download">
    <div class="section-wrapper">
      <div class="download-element-list-wrapper">
        <div
          class="download-element"
          v-for="document in props.content.download_list">
          <SharedBasicDownload :document="document" />
        </div>
      </div>
    </div>
  </section>
  <section v-else-if="content.geo_visibility == 'only_americas' && actualRegion == 'America'" class="section section--download">
    <div class="section-wrapper">
      <div class="download-element-list-wrapper">
        <div
          class="download-element"
          v-for="document in props.content.download_list">
          <SharedBasicDownload :document="document" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.section--download {
  padding-bottom: pxrem(60);
  padding-top: pxrem(60);

  @include mq(md) {
    padding-bottom: pxrem(120);
    padding-top: pxrem(120);
  }

  .section-wrapper {
    @include make-col-ready;

    @include mq(md) {
      @include make-col(20);
      @include make-col-offset(2);
    }
  }

  .download-element {
    margin-bottom: pxrem(30);
  }

  // .download-link {
  //   display: flex;
  //   text-decoration: none;

  //   &:hover {
  //     .btn {
  //       background-color: $color-blue;
  //       border-color: $color-blue;
  //       color: $color-white;

  //       svg {
  //         @include icon-color($color: $color-white);
  //       }
  //     }
  //   }
  // }

  // .download-link-left {
  //   flex-shrink: 0;

  //   .btn {
  //     margin-top: pxrem(5);
  //   }
  // }

  // .download-link-right {
  //   margin-left: pxrem(10);
  //   flex-grow: 1;
  // }

  // .document-title {
  //   @include paragraph;
  //   font-weight: 600;
  // }

  // .document-date {
  //   @include paragraph;
  // }
}
</style>
