<script setup>
import { onMounted } from "vue";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  pattern: {
    type: Boolean,
    default: false,
  },
})

// get route parameters
const route = useRoute()
const actualRegion = ref('')

// const {locale, getBrowserLocale} = useI18n()
// const browserLocale = getBrowserLocale()
// console.log('browserLocale:', browserLocale)

function getRegion() {
  const locale = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log('Intl', Intl.DateTimeFormat().resolvedOptions())
  const region = locale.split('/')[0] || 'default';
  return region;
}

onMounted(() => {
  actualRegion.value = getRegion()
})
</script>

<template>
  <transition>
    <section v-if="!content.geo_visibility || content.geo_visibility == 'world'" class="paragraph paragraph--pretitle-title-text-and-slider">
      <div class="paragraph-wrapper">
        <div
          class="paragraph-wrapper-left animate-in-stagger-bottom animate-out-left">
          <div
            class="paragraph-pretitle animate-stagger-item"
            v-html="content.pretitle"></div>
          <h2
            class="paragraph-title animate-stagger-item"
            v-html="content.title"></h2>
          <div
            class="paragraph-text animate-stagger-item"
            v-html="content.text"></div>
        </div>
        <div class="paragraph-wrapper-right">
          <SharedSlidersFullSize
            :images="content.images"
            color="dark"
            aspect="landscape-regular" />
          <SharedPattern
            class="paragraph-pattern animate-out-right"
            :sector="route.params.sectorSlug"
            type="gallery"
            v-if="props.pattern && route.params.sectorSlug" />
        </div>
      </div>
    </section>
    <section v-else-if="content.geo_visibility == 'world_but_americas' && actualRegion != 'America'" class="paragraph paragraph--pretitle-title-text-and-slider">
      <div class="paragraph-wrapper">
        <div
          class="paragraph-wrapper-left animate-in-stagger-bottom animate-out-left">
          <div
            class="paragraph-pretitle animate-stagger-item"
            v-html="content.pretitle"></div>
          <h2
            class="paragraph-title animate-stagger-item"
            v-html="content.title"></h2>
          <div
            class="paragraph-text animate-stagger-item"
            v-html="content.text"></div>
        </div>
        <div class="paragraph-wrapper-right">
          <SharedSlidersFullSize
            :images="content.images"
            color="dark"
            aspect="landscape-regular" />
          <SharedPattern
            class="paragraph-pattern animate-out-right"
            :sector="route.params.sectorSlug"
            type="gallery"
            v-if="props.pattern && route.params.sectorSlug" />
        </div>
      </div>
    </section>
    <section v-else-if="content.geo_visibility == 'only_americas' && actualRegion == 'America'" class="paragraph paragraph--pretitle-title-text-and-slider">
      <div class="paragraph-wrapper">
        <div
          class="paragraph-wrapper-left animate-in-stagger-bottom animate-out-left">
          <div
            class="paragraph-pretitle animate-stagger-item"
            v-html="content.pretitle"></div>
          <h2
            class="paragraph-title animate-stagger-item"
            v-html="content.title"></h2>
          <div
            class="paragraph-text animate-stagger-item"
            v-html="content.text"></div>
        </div>
        <div class="paragraph-wrapper-right">
          <SharedSlidersFullSize
            :images="content.images"
            color="dark"
            aspect="landscape-regular" />
          <SharedPattern
            class="paragraph-pattern animate-out-right"
            :sector="route.params.sectorSlug"
            type="gallery"
            v-if="props.pattern && route.params.sectorSlug" />
        </div> 
      </div>
    </section>
  </transition>
</template>

<style lang="scss">
.paragraph--pretitle-title-text-and-slider {
  margin-bottom: pxrem(60);
  margin-top: pxrem(60);

  @include mq(md) {
    margin-bottom: pxrem(160);
    margin-top: pxrem(160);
  }

  .paragraph-wrapper {
    display: flex;
    flex-direction: column-reverse;

    @include mq(md) {
      align-items: center;
      flex-direction: row;
    }
  }

  .paragraph-wrapper-left {
    @include mq(md) {
      @include make-col(7);
    }
  }

  .paragraph-wrapper-right {
    margin-bottom: pxrem(50);
    position: relative;

    @include mq(md) {
      @include make-col(10);
      @include make-col-offset(3);
      margin-bottom: 0;
    }
  }

  .paragraph-pretitle {
    @include label;
    margin-bottom: pxrem(8);
  }

  .paragraph-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(15);

    @include mq(md) {
      margin-bottom: pxrem(25);
    }
  }

  .paragraph-text {
    @include paragraph;
    font-weight: 400;

    @include mq(md) {
      margin-bottom: 0;
    }

    p {
      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .paragraph-pattern {
    bottom: -10%;
    position: absolute;
    right: 0;

    @include mq(md) {
      bottom: initial;
      left: -20%;
      top: -15%;
      right: initial;
    }
  }
}
</style>